<template>
  <div class="landing">
    <div class="container">
      <div class="header">
        <div class="logo">
          <img src="@/assets/images/logo.png" alt="V" />
        </div>
        <div class="info">
          <div class="contacts">
            <div>
              <font-awesome-icon
                class="contact-icon"
                :icon="['fas', 'phone-alt']"
              />
              <p>01093895621</p>
            </div>
            <div>
              <font-awesome-icon
                class="contact-icon"
                :icon="['far', 'envelope']"
              />
              <p>sales@vcarts.net</p>
            </div>
          </div>
          <div class="social-icons">
            <ul>
              <li>
                <a href="https://www.facebook.com/vcarts.net" target="_blank">
                  <font-awesome-icon
                    class="social-icon"
                    :icon="['fab', 'facebook']"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UC971CXG72GCO5pAjLwVktwA"
                  target="_blank"
                  ><font-awesome-icon
                    class="social-icon"
                    :icon="['fab', 'youtube']"
                /></a>
              </li>
              <li>
                <a href="https://www.instagram.com/vcartsnet/" target="_blank"
                  ><font-awesome-icon
                    class="social-icon"
                    :icon="['fab', 'instagram']"
                /></a>
              </li>
              <li>
                <a href="https://twitter.com/Vcartsnet" target="_blank"
                  ><font-awesome-icon
                    class="social-icon"
                    :icon="['fab', 'twitter']"
                /></a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/vcarts"
                  target="_blank"
                  ><font-awesome-icon
                    class="social-icon"
                    :icon="['fab', 'linkedin-in']"
                /></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="landing-body">
        <div class="form">
          <div class="text">
            <img src="@/assets/images/cs.png" alt="" />
            <p>You need an E-Commerce? Just let us know</p>
          </div>
          <validation-observer
            v-slot="{ invalid }"
            @submit.prevent="submit"
            ref="landingForm"
          >
            <form>
              <div class="content">
                <validation-provider
                  name="name"
                  rules="required|min:2|max:150"
                  v-slot="errors"
                >
                  <div class="form-field">
                    <input
                      type="text"
                      v-model="name"
                      name="name"
                      placeholder="Full name"
                      class="input-floating-label"
                      id="name"
                    />
                    <label for="name">Full name</label>
                    <div
                      class="text-red-500 h-6 mb-6"
                      :style="errors.length ? 'visibility: visible;' : ''"
                    >
                      {{ errors[0] }}
                    </div>
                  </div>
                </validation-provider>

                <validation-provider
                  name="email"
                  rules="required|min:2|max:150"
                  v-slot="errors"
                >
                  <div class="form-field">
                    <input
                      type="text"
                      v-model="email"
                      name="email"
                      class="input-floating-label"
                      placeholder="Your Email"
                      id="email"
                    />
                    <label for="mail">Your mail</label>
                    <div
                      class="text-red-500 h-6 mb-6"
                      :style="errors.length ? 'visibility: visible;' : ''"
                    >
                      {{ errors[0] }}
                    </div>
                  </div>
                </validation-provider>

                <validation-provider
                  name="phone"
                  rules="required|min:2|max:150"
                  v-slot="errors"
                >
                  <div class="form-field">
                    <input
                      type="text"
                      v-model="phone"
                      name="phone"
                      placeholder="Your Phone"
                      class="input-floating-label phone-input"
                      id="phone"
                    />
                    <label for="phone">Your phone</label>
                    <div
                      class="text-red-500 h-6 mb-6"
                      :style="errors.length ? 'visibility: visible;' : ''"
                    >
                      {{ errors[0] }}
                    </div>
                  </div>
                </validation-provider>
                <div class="btn">
                  <button
                    @click.prevent="submit()"
                    :disabled="invalid"
                    class="form-btn px-10 py-3"
                    :class="invalid ? 'invalid-form-btn' : 'valid-form-btn'"
                  >
                    <img src="@/assets/images/send.png" alt="" />
                  </button>
                </div>
              </div>
            </form>
          </validation-observer>
        </div>
        <div class="show">
          <div class="content">
            <div class="iframe-container">
              <div>
                <!-- or with a custom component identifier -->
                <!-- <youtube-media :video-id="VHML_TmWz0w"></youtube-media> -->

                <iframe
                  id="player"
                  type="text/html"
                  width="1920"
                  height="1080"
                  src="https://www.youtube.com/embed/VHML_TmWz0w?enablejsapi=1&amp;modestbranding=0&amp;autohide=1&amp;showinfo=0&amp;rel=0&amp;controls=0&amp;autoplay=1&amp;loop=1&amp;playlist=VHML_TmWz0w&amp;autopause=1&amp;mute=1"
                  allow="accelerometer; encrypted-media; gyroscope; picture-in-picture;autoplay=1&amp;"
                  frameborder="0"
                ></iframe>
              </div>
            </div>
            <figure>
              <img src="@/assets/images/bott.png" alt="" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: "",
      email: "",
      phone: "",
    };
  },
  mounted() {
    // this.getSettings();
  },
  methods: {
    submit() {
      this.axios
        .post("contacts", {
          name: this.name,
          email: this.email,
          phone: this.phone,
        })
        .then(() => {
          this.$toasted.show("your data sent successfully", {
            type: "success",
          });
          this.$refs.landingForm.reset();
          this.name = "";
          this.email = "";
          this.phone = "";
        })
        .catch((err) => {
          this.$toasted.show(err.response.data.message, { type: "error" });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
// .is-rtl .landing-page .landing-lang {
//   @apply border-l border-r-0;
// }
// .is-rtl .landing-page .landing-social {
//   @apply border-l border-r-0;
// }
</style>
